



























































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import OrderApi, { EneoOrderRequest } from "@/services/api/order";
import { AxiosError, AxiosResponse } from "axios";
import ErrorService, { ValidationErrors } from "@/services/errors";
import Order from "@/models/order";
import {
    mdiAlert,
    mdiCallMade,
    mdiCheckCircle,
    mdiLightbulb,
    mdiLightbulbOnOutline,
    mdiPlus,
} from "@mdi/js";
import { Action, Getter } from "vuex-class";
import { User } from "@/models/user";
import UserApi from "@/services/api/user";
import firebase, {
    addAnalyticsEvent,
    ensureAuthenticated,
} from "@/plugins/firebase";
import { captureSentryException, captureSentryMessage } from "@/plugins/sentry";
import StatusCodes from "http-status-codes";
import CompositeAlert from "@/components/CompositeAlert.vue";
import UserProfile from "@/models/user-profile";
import { SelectItem } from "@/types/veutify";
import Utilities, { GetUtilitiesAmountRequest } from "@/services/api/utilities";
import BillAmount from "@/models/bill-amount";

@Component({
    components: {
        CompositeAlert,
    },
})
export default class EneoIndex extends Vue {
    formEmail: string = "";
    formPaymentMethod: string = "mtn-mobile-money";
    formPhoneNumber: string = "";
    electricityIcon: string = mdiLightbulbOnOutline;
    formInputErrors: ValidationErrors = new ValidationErrors();
    isSubmitting: boolean = false;
    isValidatingBillNumber = false;
    billAmount: BillAmount | null = null;
    formAmount = 1100;
    payIcon: string = mdiCallMade;
    invoiceIcon: string = mdiLightbulb;
    plusIcon: string = mdiPlus;
    formMeterType = "eneo-prepay";
    formBillNumber = "";
    formMeterNumber = "";
    tickIcon: string = mdiCheckCircle;
    alertIcon: string = mdiAlert;
    billNumberIsValid = false;

    @Getter("user") user!: User | null;
    @Getter("userIsAnonymous") userIsAnonymous!: boolean;
    @Getter("authToken") authToken!: string;
    @Getter("userIsAdmin") userIsAdmin!: boolean;
    @Getter("referralCode") referralCode!: string;
    @Action("setUser") setUser!: (user: User | null) => void;
    @Action("setAuthToken") setAuthToken!: (authToken: string | null) => void;

    get meterTypes(): Array<SelectItem> {
        return [
            {
                value: "eneo-prepay",
                text: "Prepaid",
            },
            {
                value: "eneo-postpay",
                text: "Postpaid",
            },
        ];
    }

    get isPrepaid(): boolean {
        return this.formMeterType === "eneo-prepay";
    }

    get formPhoneNumberPlaceholder(): string {
        if (!this.formPaymentMethod) {
            return "Phone number e.g 6xxxxxxxx";
        }
        return `${this.paymentMethodString} number e.g 6xxxxxxxx.`;
    }

    get paymentMethodString(): string {
        if (this.formPaymentMethod == "mtn-mobile-money") {
            return "MTN Mobile Money";
        }
        return "Orange Money";
    }

    get canCalculateFees(): boolean {
        return this.billAmount !== null;
    }

    payEneoBill() {
        addAnalyticsEvent("pay_eneo_clicked");
        this.isSubmitting = true;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleError = (error: any) => {
            this.isSubmitting = false;
            this.handleError(error);
        };

        this.$recaptcha("pay_eneo_bill")
            .then((token: string) => {
                const payload: EneoOrderRequest = {
                    captcha: token,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    payment_method: this.formPaymentMethod,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phone_number: this.formPhoneNumber,
                    email: this.formEmail,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    bill_number: this.formBillNumber,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    meter_number: this.formMeterNumber,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    meter_type: this.formMeterType,
                    amount: parseInt(this.formAmount.toString()),
                };
                this.ensureUserExists()
                    .then(() => {
                        this.createOrder(payload);
                    })
                    .catch(handleError);
            })
            .catch(handleError);
    }

    setProfileFromAuthUser() {
        this.formEmail = this.user?.email || "";
    }

    getAmount() {
        this.isValidatingBillNumber = true;
        this.billNumberIsValid = false;
        this.billAmount = null;

        this.$recaptcha("validate_bill_number")
            .then((token: string) => {
                const params: GetUtilitiesAmountRequest = {
                    captcha: token,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    bill_number:
                        this.formMeterType == "eneo-postpay"
                            ? this.formBillNumber
                            : this.formMeterNumber,
                    amount: parseInt(this.formAmount.toString()),
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    payment_method: this.formPaymentMethod,
                    category: this.formMeterType,
                };
                Utilities.getAmount(params)
                    .then((response: AxiosResponse) => {
                        this.formInputErrors.clear("bill_number");
                        this.billAmount = new BillAmount(response.data.data);
                        this.formAmount = this.billAmount.totalAmount;
                        this.billNumberIsValid = true;
                    })
                    .catch(this.handleAxiosError)
                    .finally(() => {
                        this.isValidatingBillNumber = false;
                    });
            })
            .catch((error) => {
                this.isValidatingBillNumber = false;
                this.handleError(error);
            });
    }

    loadProfile() {
        if (!firebase.auth().currentUser?.uid) {
            captureSentryException(
                "cannot load profile for an unauthenticated user"
            );
            return;
        }

        UserApi.getProfile(firebase.auth().currentUser?.uid as string)
            .then((response: AxiosResponse) => {
                const userProfile = new UserProfile(response.data.data);
                this.formPhoneNumber = userProfile.paymentPhoneNumber ?? "";
                this.formEmail = userProfile.email ?? this.formEmail;
                this.formMeterType =
                    userProfile.eneo?.meterType ?? this.formMeterType;
                this.formMeterNumber =
                    userProfile.eneo?.meterNumber ?? this.formMeterNumber;
                if (this.isPrepaid) {
                    this.formAmount =
                        userProfile.eneo?.amount ?? this.formAmount;
                }
                if (this.formMeterNumber && this.isPrepaid) {
                    this.getAmount();
                }
            })
            .catch((error: AxiosError) => {
                if (error.response?.status === StatusCodes.NOT_FOUND) {
                    this.setProfileFromAuthUser();
                    return;
                }
                this.handleAxiosError(error);
            });
    }

    mounted() {
        if (typeof this.$route.query.t === "string") {
            this.formMeterType = this.$route.query.t.trim();
        }

        if (typeof this.$route.query.n === "string") {
            if (this.isPrepaid) {
                this.formMeterNumber = this.$route.query.n.trim();
            } else {
                this.formBillNumber = this.$route.query.n.trim();
            }
        }

        if (this.user || this.userIsAnonymous) {
            ensureAuthenticated()
                .then(() => {
                    this.loadProfile();
                })
                .catch(this.handleError);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any) {
        if (error instanceof Error && error.message) {
            captureSentryException(error);
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.ERROR,
                error.message
            );
            return;
        }

        captureSentryMessage(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            "We could not carry out your request. It may be because your internet connection is not so good. Please try again"
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ensureUserExists(): Promise<any> {
        if (this.authToken) {
            return new Promise<boolean>((resolve) => resolve(true));
        }
        return firebase
            .auth()
            .signInAnonymously()
            .then(async (response) => {
                this.setUser(response.user);
                this.setAuthToken((await response.user?.getIdToken()) || null);
                addAnalyticsEvent("anonymous_user_created_before_order");
            });
    }

    createOrder(payload: EneoOrderRequest) {
        OrderApi.createEneo(payload)
            .then((response: AxiosResponse) => {
                const order = new Order(response.data.data);
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    "Your order has been created successfully"
                );

                addAnalyticsEvent("begin_checkout", {
                    currency: "XAF",
                    value: order.paymentAmount,
                    items: [
                        {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_id: order.itemId,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_name: order.itemName,
                            affiliation: "ENEO",
                            currency: "XAF",
                        },
                    ],
                });

                this.$router.push({
                    name: this.$constants.ROUTE_NAMES.ORDERS_SHOW,
                    params: {
                        orderId: order.orderId,
                    },
                });
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    handleAxiosError(error: AxiosError) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        this.handleError(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
